<template>
  <div class="grid grid-cols-1 mt-5">
    <div class="text-center">
      <h1 style="font-size:24pt"><strong>Algo Research</strong></h1>
    </div>
    <div class="text-center mt-3">
      <h1><strong>Reset password</strong></h1>
    </div>
    <div  class="text-center mt-5">
      Fill a field below, to reset your password.
      <h2 v-if="!verifying && !verification">{{ error }}</h2>
    </div>
    <div class="md:container text-center mx-auto mt-5">
      <InputTextBox
        class="px-0 py-2 max-w-sm mx-auto"
        type="password"
        placeholder="Enter your password"
        @input="val => setPasswordValue(val) "
        :hide-errors="!passwordError"
        :disabled="processing"
        warning="Please check again"
        warning-class="text-red-500"
      />
    </div>
    <div class="md:container text-center mx-auto mt-5">
      <ButtonSolidBox
        class="w-full h-full max-w-sm mx-auto"
        color="primary"
        :disabled="verifying"
        @click="resetPassword()"
      >
        Reset
      </ButtonSolidBox>
    </div>
    <div  class="text-center mt-5">
      <h3 v-if="verifying"><small>Please wait, it won't take long.</small></h3>
      <h3 v-if="!verifying && verification"><small>{{ message }}, Will automatically redirect within {{ counter }} second(s).</small></h3>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
export default ({
  components: {
    InputTextBox,
    ButtonSolidBox
  },
  data () {
    return {
      passwordValue: '',
      passwordError: false,
      verifying: false,
      verification: false,
      error: '',
      counter: 5,
      message: ''
    }
  },
  methods: {
    setPasswordValue (val) {
      this.passwordValue = val
    },
    countRedirect () {
      setTimeout(() => {
        if (this.counter === 1) {
          this.$router.push({ name: 'route.root' })
        } else {
          this.counter--
          this.countRedirect()
        }
      }, 1000)
    },
    resetPassword () {
      if (this.passwordValue === '') {
        this.passwordError = true
        return
      }
      this.verifying = true
      this.passwordError = false
      axios.post(process.env.VUE_APP_API_URL + '/v1/internal-user/reset', { email: this.$route.query.email, token: this.$route.query.token, new_password: this.passwordValue })
        .then(async (result) => {
          if (result.data.data !== undefined && result.data.data === 'ok') {
            this.verifying = false
            this.verification = true
            this.message = 'Password successfully changed'
          } else {
            this.verifying = false
            this.verification = false
            this.error = 'Failed to change password, you can try request a reset password again.'
          }
          this.countRedirect()
          // this.processing = false
        })
        .catch((_) => {
          // console.log(err)
          this.error = 'Ooppss! something wrong happened.'
          this.verifying = false
          this.verification = false
          this.countRedirect()
        })
    }
  },
  created () {
    if (this.$route.query.email === undefined && this.$route.query.token === undefined) {
      this.$router.push({ name: 'route.root' })
    }
  }
})
</script>
